import { BalanceInfo, Statement } from "apiv1/payjp";
import { memo } from "react";
import styles from "./BalanceDetail.module.css";
import stylesdetail from "..//SalesMainOrders/ModalSalesOrder/ModalSalesOrder.module.css";

type BalanceDetailProps = {
  balance: BalanceInfo | null;
};

const BalanceDetail = ({ balance }: BalanceDetailProps) => {
  if (balance === null) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.cont}>
        <h3 className={styles.header}>詳細</h3>
        <div>
          <ul className={stylesdetail.list}>
            <li className={stylesdetail.inner}>
              <h4 className={stylesdetail.status}>種別</h4>
              <p className={stylesdetail.txt}>{formatState(balance.state)}</p>
            </li>
            <li className={stylesdetail.inner}>
              <h4 className={stylesdetail.status}>状態</h4>
              <p className={stylesdetail.txt}>
                {formatClosed(balance.state, balance.closed)}
              </p>
            </li>
            <li className={stylesdetail.inner}>
              <h4 className={stylesdetail.status}>金額</h4>
              <p className={stylesdetail.txt} style={{ fontWeight: "bold" }}>
                {formatYen(balance.net)}
              </p>
            </li>
            <li className={stylesdetail.inner}>
              <h4 className={stylesdetail.status}>入金予定日</h4>
              <p className={stylesdetail.txt}>
                {balance.dueDate
                  ? formatDateJP(new Date(balance.dueDate))
                  : "-"}
              </p>
            </li>
          </ul>
        </div>
      </div>
      <div className={styles.cont}>
        <h3 className={styles.header}>内訳</h3>
        <table className={styles.table}>
          <tr className={styles.thead}>
            <th className={styles.theadMonth}>種別</th>
            <th className={styles.theadMonth}>売上</th>
            <th className={styles.theadMonth}>手数料</th>
            <th className={styles.theadMonth}>返金</th>
            <th className={styles.theadMonth}>チャージバック</th>
            <th className={styles.theadMonth}>振り込み手数料</th>
            <th className={styles.theadMonth}>その他</th>
            <th className={styles.theadMonth}>計</th>
          </tr>
          {balance.statements.map((statement) => {
            return (
              <tr className={styles.box}>
                <td className={styles.tdataMonth} data-label="種別">
                  {formatType(statement.type)}
                  {formatTerm(statement.startAt, statement.endAt)}
                </td>
                <td className={styles.tdataStatus} data-label="売上">
                  {formatYen(statement.details.grossSales)}
                </td>
                <td className={styles.tdataStatus} data-label="手数料">
                  {formatYen(statement.details.fee)}
                </td>
                <td className={styles.tdataStatus} data-label="返金">
                  {formatYen(statement.details.refundFee)}
                </td>
                <td className={styles.tdataStatus} data-label="チャージバック">
                  {formatYen(statement.details.chargeback)}
                </td>
                <td className={styles.tdataStatus} data-label="振り込み手数料">
                  {formatYen(statement.details.transferFee)}
                </td>
                <td className={styles.tdataStatus} data-label="その他">
                  {formatYen(statement.details.other)}
                </td>
                <td className={styles.tdataProfit} data-label="計">
                  {formatYen(statement.net)}
                </td>
              </tr>
            );
          })}
        </table>
      </div>
    </div>
  );
};

const formatState = (state: BalanceInfo["state"]): string => {
  switch (state) {
    case "transfer":
      return "入金";
    case "collecting":
      return "集計中";
    case "claim":
      return "請求";
  }
};

const formatClosed = (state: BalanceInfo["state"], closed: boolean): string => {
  if (state === "transfer") {
    return closed ? "入金済" : "入金待ち";
  } else if (state === "collecting" && !closed) {
    return "次回繰越";
  } else if (closed) {
    return "完了";
  } else {
    return "未完了";
  }
};

const formatType = (state: Statement["type"]): string => {
  switch (state) {
    case "sales":
      return "売上";
    case "transfer_fee":
      return "振り込み手数料";
    default:
      return "その他";
  }
};

const formatTerm = (startAt: string | null, endAt: string | null) => {
  if (!startAt) {
    return null;
  }

  if (!endAt) {
    return `（${formatDateJP(new Date(startAt))} 〜）`;
  }

  // 集計期間が startAt <= x < endAt のため、期間の年月日にするには直前の時刻にする必要がある
  const msecEndAt = Date.parse(endAt);
  const dateEndAt = new Date(msecEndAt - 1);

  return `（${formatDateJP(new Date(startAt))} 〜 ${formatDateJP(dateEndAt)}）`;
};

const formatDateJP = (d: Date): string => {
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  return `${year}年${month}月${date}日`;
};

const formatYen = (yen: number): string => {
  if (yen === 0) {
    return "-";
  }

  return yen.toLocaleString("ja-JP") + "円";
};

export default memo(BalanceDetail);

import { BalanceInfo, TransferInfo, TransferStatus } from "apiv1/payjp";
import { SpinnerCenter } from "components/Spinner";
import React, { useCallback, useState } from "react";
import { WithLoad } from "utils/types";
import styles from "./SalesMainPayment.module.css";
import BalanceRow from "./BalanceRow";
import BalanceDetail from "./BalanceDetail";
import Modal from "components/Modal";

const formatDateJP = (ios: string): string => {
  const d = new Date(ios);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  return `${year}年${month}月${date}日`;
};

const formatDateMonth = (ios: string): string => {
  const d = new Date(ios);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  return `${year}年${month}月`;
};

const formatScheduledDate = (d: string): string => {
  const [year, month, date] = d.split("-");
  return `${year}年${month}月${date}日`;
};

type ScheduledBalanceProps = {
  balance: BalanceInfo | undefined;
};

const ScheduledBalance = ({ balance }: ScheduledBalanceProps) => {
  if (balance == null) {
    return (
      <div className={styles.monthly}>
        <label className={styles.label}>入金予定</label>
        <p className={styles.monthlyDeposit}>入金予定はありません</p>
      </div>
    );
  }

  return (
    <div className={styles.monthly}>
      <label className={styles.label}>入金予定額</label>
      <p className={styles.monthlySales}>
        ¥{balance.net.toLocaleString("ja-JP")}
      </p>
      <label className={styles.label}>入金予定日</label>
      <p className={styles.monthlyDeposit}>
        {balance.dueDate ? formatDateJP(balance.dueDate) : "未定"}
      </p>
    </div>
  );
};

const statusToString = (status: TransferStatus): string => {
  switch (status) {
    case "pending":
      return "入金待ち";
    case "paid":
      return "入金済";
    case "failed":
      return "入金失敗";
    case "stop":
      return "一時停止";
    case "carried_over":
      return "次回繰越";
    case "recombination":
      return "入金失敗";
    default:
      return "不明";
  }
};

const toYenString = (x: number | null): string => {
  if (x == null || x === 0) {
    return "-";
  }

  return `¥${x.toLocaleString("ja-JP")}`;
};

type TransferRowProps = {
  transfer: TransferInfo;
};

const TransferRow: React.FC<TransferRowProps> = ({ transfer }) => {
  let transferDate = "-";
  if (transfer.transfer_date) {
    transferDate = formatScheduledDate(transfer.transfer_date);
  } else if (transfer.status === "pending") {
    transferDate = `${formatScheduledDate(transfer.scheduled_date)}（予定）`;
  }

  let amount = "-";
  if (transfer.transfer_amount) {
    amount = `¥${transfer.transfer_amount.toLocaleString("ja-JP")}`;
  } else if (transfer.status === "pending") {
    amount = `（予定）¥${(
      transfer.amount + (transfer.carried_balance || 0)
    ).toLocaleString("ja-JP")}`;
  }

  return (
    <tr className={styles.box}>
      <td className={styles.tdataMonth}>
        {formatDateMonth(transfer.term_start)}
      </td>
      <td
        className={styles.tdataStatus}
        data-label="振込状況"
        data-status={transfer.status}
      >
        <span>{statusToString(transfer.status)}</span>
      </td>
      <td className={styles.tdataConfirm} data-label="受取金額確定日">
        {formatDateJP(transfer.term_end)}
      </td>
      <td className={styles.tdataDeposit} data-label="振込日">
        {transferDate}
      </td>
      <td className={styles.tdataYen} data-label="売上">
        {toYenString(transfer.charge_gross)}
      </td>
      <td className={styles.tdataYen} data-label="手数料">
        {toYenString(transfer.charge_fee + transfer.total_platform_fee)}
      </td>
      <td className={styles.tdataYen} data-label="チャージバック">
        {toYenString(transfer.dispute_amount)}
      </td>
      <td className={styles.tdataYen} data-label="繰り越し金額">
        {toYenString(transfer.carried_balance)}
      </td>
      <td className={styles.tdataProfit} data-label="振込金額">
        {amount}
      </td>
    </tr>
  );
};

type SalesMainPaymentProps = {
  balances: WithLoad<BalanceInfo[]>;
  transfers: WithLoad<TransferInfo[]>;
};

const SalesMainPayment: React.FC<SalesMainPaymentProps> = ({
  balances,
  transfers,
}) => {
  const [balance, setBalance] = useState<BalanceInfo | null>(null);
  const [isOpenBalanceDetail, setIsOpenBalanceDetail] = useState(false);

  const openBalanceDetail = useCallback((balance: BalanceInfo) => {
    setBalance(balance);
    setIsOpenBalanceDetail(true);
  }, []);

  const closeBalanceDetail = useCallback(() => {
    setIsOpenBalanceDetail(false);
  }, []);

  if (transfers === "loading" || balances === "loading") {
    return <SpinnerCenter />;
  }

  if (transfers === "notfound") {
    return (
      <div className={styles.container}>
        <p>入金履歴の取得に失敗しました。</p>
      </div>
    );
  }

  const failedFetchBalance = balances === "notfound";
  if (balances === "notfound") {
    balances = [];
  }

  const totalSalse =
    transfers
      .filter((t) => t.status === "paid")
      .reduce((a, t) => a + (t.transfer_amount || 0), 0) +
    balances.filter((b) => b.closed).reduce((a, b) => a + b.net, 0);

  const pending = balances.find(
    (balance) => balance.state === "transfer" && !balance.closed
  );

  return (
    <div className={styles.container}>
      <div>
        <div className={styles.total}>
          <small>{formatDateJP(new Date().toString())} 現在</small>
          <dl>
            <dt className={styles.totalSales}>総受け取り金額</dt>
            <dd className={styles.totalPrice}>
              {totalSalse === 0
                ? "-"
                : `¥${totalSalse.toLocaleString("ja-JP")}`}
            </dd>
          </dl>
        </div>
        <ScheduledBalance balance={pending} />
      </div>

      <table className={styles.table}>
        <tr className={styles.thead}>
          <th className={styles.theadMonth}>タイプ</th>
          <th className={styles.theadStatus}>状況</th>
          <th className={styles.theadDeposit}>入金予定日</th>
          <th className={styles.theadProfit}>金額</th>
        </tr>
        {balances.map((balance) => (
          <BalanceRow balance={balance} onClick={openBalanceDetail} />
        ))}
      </table>
      {failedFetchBalance && (
        <p className={styles.txt}>入金記録の取得に失敗しました</p>
      )}
      {!failedFetchBalance && balances.length === 0 && (
        <p className={styles.txt}>入金記録はありません</p>
      )}

      <h2 style={{ marginTop: "64px" }}>2024年6月以前</h2>
      <table className={styles.table} style={{ marginTop: "0" }}>
        <tr className={styles.thead}>
          <th className={styles.theadMonth}>月</th>
          <th className={styles.theadStatus}>振込状況</th>
          <th className={styles.theadConfirm}>受取金額確定日</th>
          <th className={styles.theadDeposit}>振込日</th>
          <th className={styles.theadYen}>売上</th>
          <th className={styles.theadYen}>手数料</th>
          <th className={styles.theadYen}>
            <span>チャージ</span>
            <span>バック</span>
          </th>
          <th className={styles.theadYen}>繰越金額</th>
          <th className={styles.theadProfit}>振込金額</th>
        </tr>
        {transfers.map((t) => (
          <TransferRow transfer={t} />
        ))}
      </table>
      {transfers.length === 0 && (
        <p className={styles.txt}>入金記録はありません</p>
      )}

      <Modal
        title="入金詳細"
        open={isOpenBalanceDetail}
        onClose={closeBalanceDetail}
      >
        <BalanceDetail balance={balance} />
      </Modal>
    </div>
  );
};

export default SalesMainPayment;

import { BalanceInfo } from "apiv1/payjp";
import styles from "./SalesMainPayment.module.css";
import { memo, useCallback } from "react";
import { cx } from "utils/style";

type BalanceRowProps = {
  balance: BalanceInfo;
  onClick: (balance: BalanceInfo) => void;
};

const BalanceRow = ({ balance, onClick }: BalanceRowProps) => {
  const handleClick = useCallback(() => {
    onClick(balance);
  }, [balance, onClick]);

  return (
    <tr className={cx(styles.box, styles.hover)} onClick={handleClick}>
      <td className={styles.tdataMonth}>{formatState(balance.state)}</td>
      <td
        className={styles.tdataStatus}
        data-label="状況"
        data-status={toLabelStatus(balance.state, balance.closed)}
      >
        <span>{formatClosed(balance.state, balance.closed)}</span>
      </td>
      <td className={styles.tdataDeposit} data-label="入金予定日">
        {formatDateJP(balance.dueDate)}
      </td>
      <td className={styles.tdataProfit} data-label="金額">
        {balance.state === "collecting" && "（予定）"}
        {toYenString(balance.net)}
      </td>
    </tr>
  );
};

const formatClosed = (state: BalanceInfo["state"], closed: boolean): string => {
  if (state === "transfer") {
    return closed ? "入金済" : "入金待ち";
  } else if (state === "collecting" && !closed) {
    return "次回繰越";
  } else if (closed) {
    return "完了";
  } else {
    return "未完了";
  }
};

const toLabelStatus = (
  state: BalanceInfo["state"],
  closed: boolean
): string => {
  if (state === "transfer") {
    return closed ? "paid" : "pending";
  } else if (state === "collecting" && !closed) {
    return "carried_over";
  } else if (closed) {
    return "paid";
  } else {
    return "pending";
  }
};

const formatState = (state: BalanceInfo["state"]): string => {
  switch (state) {
    case "transfer":
      return "入金";
    case "collecting":
      return "集計中";
    case "claim":
      return "請求";
  }
};

const formatDateJP = (ios: string | null): string => {
  if (!ios) {
    return "-";
  }

  const d = new Date(ios);
  const year = d.getFullYear();
  const month = d.getMonth() + 1;
  const date = d.getDate();
  return `${year}年${month}月${date}日`;
};

const toYenString = (x: number | null): string => {
  if (x == null || x === 0) {
    return "-";
  }

  return `¥${x.toLocaleString("ja-JP")}`;
};

export default memo(BalanceRow);
